<template>
    <div class="batchCandidateDetail candidate-detail-vue">
        <div class="tab-wrap">
            <button class="pagination-btn prev-page" :class="{'btn-disabled': move == 0}" @click="clickPrevPage" v-if="!dataLen">＜</button>
            <ul class="nav-tabs" id="myTab" ref="dataTabList">
                <li
                    :class="selectIndex == index ? 'tab-item active' : 'tab-item'"
                    v-for="(item, index) in tabItems"
                    :key="index"
                    :style="{transform: 'translateX(-' + move + 'px)'}"
                    @click.prevent="handleTabItem(item, index)"
                >
                    <span class="tab-text">{{ item.realName }}</span>
                    <span class="tab-close" @click.stop="hanleCloseTab(index)" v-if="tabItems.length > 1">
                        <i class="icon-font icon-close"></i>
                    </span>
                </li>
            </ul>
            <!-- <button class="pagination-btn next-page" :class="{'btn-disabled': move == (this.dataTabListRealWidth - this.dataTabListViewWidth + 160)}" @click="clickNextPage" v-if="!dataLen">＞</button> -->
            <button class="pagination-btn next-page" :class="{'btn-disabled': move == (this.dataTabListRealWidth - this.dataTabListViewWidth)}" @click="clickNextPage" v-if="!dataLen">＞</button>
        </div>

        <!-- template上不能使用key, 但 v-for 必须要指定key，所以循环遍历的元素上，需要加上key -->
        <template class="iframe-content" v-for="(ifram, index) in tabItems">
            <iframe
                v-if="selectIndex == index"
                :key="ifram.resumeId || ifram.tbdResumeId"
                :src="`/Headhunting/BatchCandidate.html#/candidateDetail/${ifram.resumeId || ifram.tbdResumeId}`"
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="auto"
            ></iframe>
        </template>

        <!-- <footer-container></footer-container> -->
    </div>
</template>

<script>
import FooterContainer from '@/hr/component/footer.vue';

export default {
    componentName: "batchCandidateDetail",
    components: {
        FooterContainer,
    },
    computed: {},
    data() {
        return {
            selectIndex: 0,
            tabItems: [],

            count: 1,
            move: 0,
	        dataTabListRealWidth: 0,
	        dataTabListViewWidth: 0,
	        dataLen: false,

            moveDifference: 0,
        };
    },
    watch: {
        
    },
    mounted() {

        if(sessionStorage.getItem(`batchCandidateSelectIndex`)) {
            this.selectIndex = sessionStorage.getItem(`batchCandidateSelectIndex`);
        }

        this.tabItems = JSON.parse(sessionStorage.getItem(`batchViewCandidate`));
        // console.log(this.tabItems);


        this.$nextTick(() => {
            this.dataTabListRealWidth = this.$refs.dataTabList.scrollWidth; // scrollWidth获取整个菜单实际宽度
            this.dataTabListViewWidth = this.$refs.dataTabList.offsetWidth; // offsetWidth获取菜单在当前页面可视宽度

            this.count = Number((this.dataTabListViewWidth / 160).toFixed(0));
            // console.log(this.count);

            if(this.dataTabListRealWidth <= this.dataTabListViewWidth){ // tab不多的时候不显示左右两边的操作按钮
                this.dataLen = true;
            } else {
                this.dataLen = false;
            }
        })
        
	    // 窗口大小变化时触发。实时更新可视宽度
	    window.onresize = () => {
	        return (() => {
	            this.dataTabListRealWidth = this.$refs.dataTabList.scrollWidth;
	            this.dataTabListViewWidth = this.$refs.dataTabList.offsetWidth;

                this.count = Number((this.dataTabListViewWidth / 160).toFixed(0));

	            if(this.move > this.dataTabListViewWidth) {
	                this.move = this.dataTabListViewWidth;
	            }

                if(this.dataTabListRealWidth <= this.dataTabListViewWidth){ // tab不多的时候不显示左右两边的操作按钮
                    this.dataLen = true;
                } else {
                    this.dataLen = false;
                }
	        })()}
    },
    methods: {
        // 当菜单项向右的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即右移移动一个菜单项的宽度），
	    // 当菜单项向右的可移动距离小于单个菜单项的宽度时，move等于0（即回到最开始没有移动的状态）
        clickPrevPage() {
            // console.log('点击上一页');
            // console.log(this.move);

            // if(this.move > (160 * this.count)) {
            if(this.move > (160 * 5)) {
                // this.move = this.move - (160 * this.count);
                this.move = this.move - (160 * 5);
            } else {
                this.move = 0;
            }
        },
        // 当菜单项向左的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即左移移动一个菜单项的宽度），
        // 当菜单项向左的可移动距离小于单个菜单项的宽度时，move等于可以向左移动的最大值（即到最末尾）
        // 实际宽度减去可视宽度就是可移动的范围即move的范围
        clickNextPage() {
            // console.log('点击下一页');
            // console.log(this.move);

            // if(this.move < this.dataTabListRealWidth - this.dataTabListViewWidth - (160 * this.count)){
            if(this.move < this.dataTabListRealWidth - this.dataTabListViewWidth - (160 * 5)){
                // this.move = this.move + (160 * this.count);
                // this.move = this.move + (160 * 5);
                this.move = this.move + (160 * 5) - this.moveDifference;
            } else{
                // this.move = this.dataTabListRealWidth - this.dataTabListViewWidth + 160;
                // this.move = this.dataTabListRealWidth - this.dataTabListViewWidth;
                this.move = this.dataTabListRealWidth - this.dataTabListViewWidth - this.moveDifference;
            }
        },

        handleTabItem(item, index) {
            // console.log(item);
            this.selectIndex = index;

            sessionStorage.setItem(`batchCandidateSelectIndex`, this.selectIndex);
        },
        hanleCloseTab(index) {
            // console.log(index);
            this.tabItems.splice(index, 1);
            sessionStorage.setItem(`batchViewCandidate`,JSON.stringify(this.tabItems));

            if(this.selectIndex == index) {
                if(index > 0) {
                    this.selectIndex = index - 1;
                    sessionStorage.setItem(`batchCandidateSelectIndex`, this.selectIndex);
                }
            } else if(this.selectIndex > index) {
                this.selectIndex = this.selectIndex - 1;
                sessionStorage.setItem(`batchCandidateSelectIndex`, this.selectIndex);
            }

            this.dataTabListRealWidth = this.$refs.dataTabList.scrollWidth;
            this.dataTabListViewWidth = this.$refs.dataTabList.offsetWidth;
            // console.log(this.dataTabListRealWidth);
            // console.log(this.dataTabListViewWidth);
            // console.log(this.dataTabListRealWidth <= this.dataTabListViewWidth);
            // console.log(this.dataTabListRealWidth - this.dataTabListViewWidth - 160 < 0);

            // if(this.dataTabListRealWidth <= this.dataTabListViewWidth){ // tab不多的时候不显示左右两边的操作按钮
            if(this.dataTabListRealWidth - this.dataTabListViewWidth - 160 < 0){ // tab不多的时候不显示左右两边的操作按钮
                this.dataLen = true;
            } else {
                this.dataLen = false;
            }

            // 如果有移动的时候，删除某个tab要往后移动；当tab不多的时候不显示左右两边的操作按钮，move要置为0
            if(this.move != 0) {
                if(this.dataLen) {
                    this.move = 0;
                } else {
                    this.move = this.dataTabListRealWidth - this.dataTabListViewWidth - 160;
                    if(this.move < 0) {
                        this.move = 0;
                        // this.dataLen = true;
                    }
                }
            } else {
                this.moveDifference = 160;
            }

            // console.log(this.move);
        },
    },
};
</script>

<style lang="scss" scoped>
.batchCandidateDetail {
    height: calc(100vh - 88px);
    .tab-wrap {
        display: flex;
        background-color: #fff;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        border-bottom: 1px solid #f6f6f6;
        box-sizing: border-box;
        min-height: 44px;
        .pagination-btn {
            width: 32px;
            // background-color: #F0F0F0;
            // color: #999;
            background-color: #38BC9D;
            color: #fff;
        }
        .btn-disabled {
            cursor: not-allowed;
        }

        .nav-tabs {
            width: 100%;
            display: flex;
            overflow: hidden;
            // overflow-x: auto;
            .tab-item {
                /* 每个菜单项的宽度要固定，不然move值无法计算 */
                width: 160px;
                min-width: 160px;
                background-color: #fff;
                display: inline-block;
                position: relative;
                padding: 0 70px 0 20px;
                font-size: 18px;
                height: 32px;
                line-height: 32px;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                border-top: 1px solid transparent;
                cursor: pointer;

                /*// 过渡效果*/
                transition:transform .2s;
                .tab-text {
                    > i {
                        color: #444;
                    }
                }
                &.active {
                    border-left: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                    border-top: 2px solid #38bc9d;
                    background: #f5f5f5;
                    cursor: default;
                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 100%;
                        height: 2px;
                        background: #f5f5f5;
                        left: 0;
                        bottom: -1px;
                    }
                    .tab-text {
                        > i {
                            font-weight: bold;
                        }
                    }
                }
                .tab-text {
                    display: inline-block;
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: top;
                    > i {
                        margin-right: 10px;
                    }
                }
                .tab-close {
                    width: 20px;
                    height: 20px;
                    line-height: 16px;
                    background: #fff;
                    text-align: center;
                    border-radius: 50%;
                    position: absolute;
                    top: 5px;
                    right: 20px;
                    cursor: pointer;
                    &:hover {
                        background: #38bc9d;
                        .icon-font {
                            color: white;
                        }
                    }
                    .icon-font {
                        font-size: 12px;
                        margin-top: -4px;
                    }
                }
            }
        }
    }
}
</style>